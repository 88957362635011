
import React from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


export default function(){
   // const 
    //position:relative
    return(

       <>
        <Container>
            {/* <Row>    */}        
           {/*   <Col md={7}>
            
            </Col>  */}

{/* <div className="d-flex justify-content-end">
                <a  href="http://localhost:3000/"><img className=""  src={process.env.PUBLIC_URL + '/logo.png'}></img></a> 
                </div>  */}

                <div className="MainLogoB">
                <a  href="http://localhost:3000/"><img className=""  src={process.env.PUBLIC_URL + '/logo.png'}></img></a> 
                </div>
          
       
       {/*  <Col> */}
     {/*    <div className="d-flex justify-content-end" style={{marginTop:"30px"}}>
                <img className="icone" src={process.env.PUBLIC_URL + '/phone.png'}></img> 
                <img className="icone" src={process.env.PUBLIC_URL + '/fb.png'}></img>
                <img className="icone" src={process.env.PUBLIC_URL + '/insta.png'}></img>
                
                </div> 
                </Col>
 */}
         {/*    </Row> */}
           
          

      



        </Container>
       </>
        

    );



}
