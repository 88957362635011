
import react from 'react';
import axios from 'axios';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';





export async function Sendreq(Url,dataArray){
    let data;
  
   
       return new Promise((resolve,reject)=>{
            var bodyFormData = new FormData()
          //  bodyFormData.append("token", JSON.stringify(dataArray))
           bodyFormData.append("data", JSON.stringify(dataArray))
            axios({
                method: 'post',
                url: Url,
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(function (response) {

                if(response.data.status==true)
                {
                    
                   // alertify.set('notifier','position', 'top-right');
                    //alertify.success('הפעולה בוצעה בהצלחה!');

                    resolve(response.data.data);
                }
                else{
                    //console.log("po"+response.data.data)
                    resolve (false);
                }







            }).catch((error) => {
                alert("בקשה נכשלה");
                resolve(false);

        })



          });




    }





export default function req()
{


return(
    <>
    </>
);



}