
import React, { useEffect, useState } from "react"
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'



export default function(props){



    return(
        <>
  <div className="TotaladdisionalPrice">
                        <label>סה"כ מחיר הכרטיס:</label><label className="greentotalprice">{props.totalprice}</label>

                     </div>

        </>


    );



}
