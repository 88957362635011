
import React from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'



export default function(props){

    const {Bool,Dis,Now,Before}=props;


    function ReturnBeforePrice(){
        var num=1+(Number(Dis)/100);
        return num;


    }

   // const 
    //position:relative


    if(Bool)
    {
        return(

            <>
           <div className="coupondiv-succ">
               <span>הקופון הופעל בהצלחה! כעת תשלם {Now} במקום - {Before} סה"כ הנחה של  - {Dis}% </span>
     
              
     
     
           </div>
     
            </>
             
     
         );
    }
    if(Bool===null){
        return (
            <>
            </>
        );
    }


    else{
        return(

            <>
           <div className="coupondiv-faile">
               <span>הפעלת הקופון כשלה</span>
     
              
     
     
           </div>
     
            </>
             
     
         );


    }
 



}
