
import React from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


export default function(props){

    const {OpenHours}=props;

    return(

       <>
      <div className="OpenTimes"><img src="./clock.svg" />שעות פעילות : <b>{OpenHours['HEDayName'] + " מ - "+OpenHours['StartTime'].slice(0, 5)+" עד "+OpenHours['EndTime'].slice(0, 5)}</b></div>

       </>
        

    );



}
