
import React, { useEffect, useState } from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

import {Sendreq} from '../req';


export default function(props){
    
    const {SetAtractType,AtractType}=props;
    const [Title,SetTitle]=useState("");
    const [Description,SetDescription]=useState("");

    useEffect(async ()=>{

        const GetWelcome=await Sendreq("https://bengurion.wdev.co.il/api/client/ClientProcesses/GetWelcome",{});

        SetTitle(GetWelcome[0]["Title"]);
        SetDescription(GetWelcome[0]["Contect"])






    },[])

  
   // const 
    //position:relative
    return(

       <>
        <Container>
            <Row>
            <div className="d-flex justify-content-center">
                <Col md={7}>
       
            <div className="welcomeSession">
                <h1>{Title}</h1>
                <p>{Description}</p>
                <Row>
                    <Col md={6}><Button onClick={()=>{SetAtractType(1)}} className={AtractType===1 ? "ticket-btn green" : "ticket-btn grey"} >רכישת כרטיסים למתחם הצריף</Button></Col>
                <Col md={6}><Button onClick={()=>{SetAtractType(2)}}  className={AtractType===2 ?  "ticket-btn green"  :"ticket-btn grey" } >רכישת כרטיסים למייצג במכון</Button></Col>
                </Row>
            </div>

       
            </Col>
            </div>
            </Row>
            </Container>

       </>
        

    );



}
