
import React, { useEffect, useState,useReducer } from "react"
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import CartPrices from "./CartPrices";
import NumericRow from "./ComponenetHelper/numericnumbers";
import OpenTimeDIV from "./ComponenetHelper/OpenTimeDIV";
const useForceUpdate2 = () => useState()[1];

export default function(props){
    const {updateMainAtractCart}=props
    const {ticketdata}=props;
    const {UpdateTotalPrice,LockMainCard,DataObjectCustomersType,OpenHours,AllowBuy} =props;
    const {objectdata,StartDates,SetDate,DatePick,DataObjectBASIC,SetHourSelected,SelectedHour}=props;

    const option=props.option;
    const [ChooseObject,SetChooseObject]=useState([]);
    const [Totalcart,SetTotalcart]=useState([]);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [TotalPirce,SetTotalPirce]=useState(0);
    const [removeindex,Setremoveindex]=useState(-1);
    const [qly,setqly]=useState(1);
    const [TotalMainAtractPrice,SetTotalMainAtractPrice]=useState(0);
    

//console.log(objectdata.AttractionData);
    

    const UpdateQly=(action)=>{
        
        action == 'add' ? setqly(qly+1) : setqly(qly-1);
    }
    const setTotalAtractPrice = (action,price)=>{
        if(action=='add'){
            SetTotalMainAtractPrice(price+TotalMainAtractPrice);
            UpdateTotalPrice('add',price);
        }
        else{

            SetTotalMainAtractPrice(TotalMainAtractPrice-price);
            UpdateTotalPrice('minus',price);
        }



        //SetTotalMainAtractPrice(price);

    }


useEffect(()=>{

console.log(DataObjectCustomersType);

},[])

    useEffect(()=>{
        var total=0;
        for(var i=0;i<Totalcart.length;i++){
            total+=Totalcart[i].price;
        }
        SetTotalPirce(total)

    },[Totalcart])

     function Checkifobjectexistinarray()
    {
        for(var i=0;i<Totalcart.length;i++)
        {
           if(Totalcart[i].customertypeid==ChooseObject.customertypeid)
           {
               return  {obj:Totalcart[i],index:i};
           }

        }

        return false
    }

    function CalTotalPrice(){
        var total=0;
        for(var i=0;i<Totalcart.length;i++){
            total+=Totalcart[i].price;
        }
        SetTotalPirce(total)
    }


    return(
        <Container>
            <div className="mainEventCard">
          
            <Row>
                <Col md={3}>
                <div className="CardPhoto">
                    <img src={DataObjectBASIC.MainPhoto}></img>
                </div>
                </Col>
                <Col className="colafter" md={6}>
                <div className="CardText">
                        <h1> {DataObjectBASIC.AtractTitle} </h1>
                        <p>{DataObjectBASIC.Description}</p>

                        <OpenTimeDIV OpenHours={OpenHours} />
                        
                        
                    </div>
                </Col>
                <Col md={3}>
                    <div className="CardPrices">
                    <Form.Select  onChange={(e)=>{SetDate(e.target.value)}}  style={{marginTop:"12px",width:"90%"}} aria-label="בחירת תאריך">
                    <option value={0}>בחירת תאריך</option>
                    {StartDates.map((item,index)=>
                    
                    <option key={item.id} value={item.ForeignFormat}>{ item.Date +" - "+ item.DayName}</option>
                    )}


                    </Form.Select>

                    <Form.Select id={"HourSelect"} onChange={(e)=>{SetHourSelected(e.target.value)}} value={SelectedHour} style={{marginTop:"12px",width:"90%"}} aria-label="בחירת שעה">
                    <option value={0}>בחירת שעה</option>
                    {DatePick && DatePick.map((item,index)=>
                    
                    <option key={item.id} value={item.Hour}>{"הגעה ב - "+item.Hour.substring(0, 5) + " מלאי - " + item.Stock +" כרטיס"}</option>
                    )}


                    </Form.Select>
     
     
        

           
                {DataObjectCustomersType.map((item,index)=>
                 <NumericRow key={item.id} AllowBuy={AllowBuy} LockMainCard={LockMainCard} title={DataObjectBASIC.AtractTitle} DataObjectCustomersType={DataObjectCustomersType} key={item.id} {...item} restdata={objectdata.AttractionData[0]} qly={qly} UpdatePrice={setTotalAtractPrice} updateMainAtractCart={updateMainAtractCart}/>
                
                )}
           



      
        <div className="checkoutline"></div>

                   <span className="TotalCostAtr">סה"כ עלות כרטיס :  &nbsp;	&nbsp;	&nbsp;	&nbsp;	&nbsp;	 <span className="pricespan" style={{fontSize:"37px",color:"#40AE49"}} > ₪{TotalMainAtractPrice}</span> </span>

                    </div>


                </Col>

            </Row>
            </div>

        </Container>

    );



}
