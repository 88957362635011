
import {React,useEffect,useState,useRef} from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import CouponMessages from '../components/ComponenetHelper/CouponMessages';

import Payform from './Payform';
import {Sendreq} from '../req';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
  } from "react-router-dom";

  import $ from 'jquery';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
const axios = require('axios');
export default function(){
    const nameForm = useRef(null)
    const history = useHistory();
    const [FirstName,SetFirstName]=useState('');
    const [LastName,SetLastName]=useState('');
    const [Email,SetEmail]=useState('');
    const [Phone,SetPhone]=useState('');
    const [cupon,Setcupon]=useState('');
    const [TotalPriceOld,SetTotalPriceOld]=useState(0);


    const [cuponMessage,SetcuponMessage]=useState(null);

    const [cuponObject,SetcuponObject]=useState(null);





    const [OrderProject,SetOrderProject]=useState([]);
    console.log(JSON.parse(sessionStorage.getItem("order")))

    useEffect(()=>{

        SetOrderProject(JSON.parse(sessionStorage.getItem("order")));
        SetTotalPriceOld(JSON.parse(sessionStorage.getItem("order"))['totalprices']);

    },[])

    async function UseCoupon(){

        if(cupon ===''){
            return;
        }
        const Cupon=await Sendreq("https://bengurion.wdev.co.il/api/client/ClientProcesses/UseCoupon",{"CouponCode":cupon,"Price":OrderProject['totalprices']});
        console.log(Cupon);
        if(Cupon !== false){
            SetcuponObject(Cupon);
            SetcuponMessage(true);
            SetOrderProject({...OrderProject,totalprices:Cupon['PriceNow'],useCoupon:true});
           
           //// sessionStorage.setItem("order",{...OrderProject,totalprices:Cupon['PriceNow'],useCoupon:true});
           var temp = JSON.parse(sessionStorage.getItem("order"));
           temp['totalprices']=Cupon['PriceNow'];
           temp['useCoupon']=true;

           sessionStorage.setItem("order",JSON.stringify(temp));

        }
        else{
            SetcuponMessage(false);
            
        }

       /// alert("תשתמש בקופון - "+cupon);
    }
    function NameValid(str){

        return /^[a-z\u0590-\u05fe]+$/i.test(str)
        //return //^[a-z\u0590-\u05fe]+$/i/.test(str);


    }

    function PhoneValid(str){

        return /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|([71,72,73,74,75,76,77]{2}\d{7})|[5]{1}\d{8})$/.test(str)
        //return //^[a-z\u0590-\u05fe]+$/i/.test(str);


    }

    function EmailValid(email){
        var re = /\S+@\S+\.\S+/;
        return re.test(email);

    }

    function Validate(){
        var erros=false;
        const form = nameForm.current;
        console.log(form)
        $('.form-control').removeClass('is-invalid')
        $('.Error').text('')

      
        if(FirstName.trim()===''){
            form['FirstName'].className='is-invalid form-control'
            $('#FirstNameError').text("יש למלא שם פרטי")
            erros=true;
        }
        else if(NameValid(FirstName)===false){
            erros=true;
            form['FirstName'].className='is-invalid form-control'
            $('#FirstNameError').text("השם פרטי יכול להכיל אותיות אנגלית או עברית בלבד")
            //alert('eror')
            

        }

        if(LastName.trim()===''){
            form['LastName'].className='is-invalid form-control'
            $('#LastNameError').text("יש למלא שם משפחה")
            erros=true;
        }
        else if(NameValid(LastName)===false){
            erros=true;
            form['LastName'].className='is-invalid form-control'
            $('#LastNameError').text("השם פרטי יכול להכיל אותיות אנגלית או עברית בלבד")
            //alert('eror')
            

        }
        if(Email.trim()===''){
            form['Email'].className='is-invalid form-control'
            $('#EmailError').text("יש למלא מייל")
            erros=true;
        }
        else if(EmailValid(Email)===false){
            erros=true;
            form['Email'].className='is-invalid form-control'
            $('#EmailError').text("להקיש כתובת מייל חוקית")
            //alert('eror')
            

        }

        if(Phone.trim()===''){
            form['Phone'].className='is-invalid form-control'
            $('#PhoneError').text("יש למלא מספר פלאפון")
            erros=true;
        }
        else if(PhoneValid(Phone)===false){
            erros=true;
            form['Phone'].className='is-invalid form-control'
            $('#PhoneError').text("יש למלא מספר טלפון תקין")
            //alert('eror')
            

        }
        if(!$('#Term').is(":checked")){
            erros=true;
            $('#TermError').text('יש לאשר את התנאים שלנו')
            $('#Term').addClass('is-invalid')
            
         

        }
        return erros;

          


    }

    async function nopost(){
      //  console.log({FirstName:FirstName,LastName:LastName,Email:Email,Phone:Phone});
     // const form = nameForm.current

     // form['FirstName'].className='is-valid form-control'
      if(Validate()){
          return;
      }
      ///console.log()

     
      var temp = JSON.parse(sessionStorage.getItem("order"));
      temp["orderpersondetails"]={FirstName:FirstName,LastName:LastName,Email:Email,Phone:Phone};
      sessionStorage.setItem("order",JSON.stringify(temp))
      console.log(temp);
      ///return;
      

      const insertOrder=await Sendreq("https://bengurion.wdev.co.il/api/client/ClientProcesses/InsertOrder",{...JSON.parse(sessionStorage.getItem("order"))});
      console.log(insertOrder);
    
      if(insertOrder === false){
          alert("פעולה נכשלה")
          return;
      }

      sessionStorage.setItem("order",JSON.stringify({...temp,orderid:insertOrder}));

      




      
     
        
        
       
    
        //console.log(JSON.parse(sessionStorage.getItem("order")));
        history.push('/orderform');
    }

    function SendPost(){
       
       /// sessionStorage.setItem("order",temp);
       

    var bodyFormData = new FormData()
    bodyFormData.append("data", JSON.stringify({amit:"try"}))
    axios({
        method: 'post',
        url: "https://bengurion.wdev.co.il/api/client/orderdata",
        data: bodyFormData,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then(function (response) {
       
       
     // history.push('/orderform');
        console.log(response.data);
        console.log(response.status);
    
    }).catch((error) => {
        alert(error);
})

    }

    return(
            
       
        <Container  className="p-4">
             <div className="orderDetailspage d-flex justify-content-center"> 
          
            <div >
          
            <h1 style={{textAlign:"center"}}>פרטי הזמנה</h1>
             
            <div className="d-flex justify-content-center"> 
               <Form ref={nameForm}>
           
                    <Row className="orderData" >
                    
                         <Col  md={6}>
                         <div className="inputblock">
                            <label  className="inputlabel" >שם פרטי</label>
                             <Form.Control name="FirstName" value={FirstName} onChange={(e)=>{SetFirstName(e.target.value)}}  />
                             <p className="Error" id="FirstNameError"></p>
                             </div>
                        </Col>

                        <Col md={6}>
                        <div className="inputblock">
                        <label className="inputlabel" >שם משפחה</label>
                        </div>
                        < Form.Control name="LastName" value={LastName} onChange={(e)=>{SetLastName(e.target.value)}} />
                        <p className="Error" id="LastNameError"></p>
                         </Col>

                         
                 
                         <Col  md={6}>
                         <div className="inputblock">
                            <label className="inputlabel" >מייל</label>
                             <Form.Control name="Email" value={Email} onChange={(e)=>{SetEmail(e.target.value)}}  />
                             <p className="Error" id="EmailError"></p>
                             </div>
                        </Col>
                        <Col   md={6}>
                        <div className="inputblock">
                        <label className="inputlabel" >טלפון</label>
                        </div>
                        < Form.Control name="Phone" value={Phone} onChange={(e)=>{SetPhone(e.target.value)}} />

                        <p  className="Error" id="PhoneError"></p>
                         </Col>   
                         <div className="space"></div>

                         
                         <Form.Check
                     
                        label="אני מעוניין לקבל עדכונים על מוצרים ופעילויות חדשות"
                         name="group1"
                        type="checkbox"
                        />
                        <div class="form-check"><input id="Term" name="group1" type={"checkbox"} class="form-check-input"/><label title="" class="form-check-label">אני מאשר</label><a href=""> את תנאי השימוש ומדיניות הביטולים</a></div>
                        <p  className="Error" id="TermError"></p>
                        <Col   md={6}>
                        <div className="inputblock">
                        <label className="inputlabel" >קוד קופון</label>
                        </div>
                        < Form.Control value={cupon} onChange={(e)=>{Setcupon(e.target.value)}} />
                        { OrderProject['useCoupon']===false &&  <span onClick={UseCoupon} className="UseCoupon">הפעלת קופון</span>}

                       { cuponMessage ===true  ? <CouponMessages Bool={cuponMessage} Dis={cuponObject.Present} Now={cuponObject.PriceNow} Before={TotalPriceOld} /> : <CouponMessages Bool={cuponMessage} />}

                         </Col>   
                        <div className="space"></div>
                        <h2>סה"כ מחיר: {OrderProject['totalprices']+'₪'}</h2>
                        
                    <Button onClick={nopost} className="nextbtn" variant="primary"></Button>{' '}

                     </Row>


                
                
             

                </Form>
                </div>



          </div>
          </div>
        </Container>
        

    );



}
