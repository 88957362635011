
import React, { useEffect, useState,useReducer } from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import {plusCircle} from "@fortawesome/free-solid-svg-icons"
//import {plusCircle} from "@fortawesome/fontawesome-svg-core"
//import {plusCircle} from "@fortawesome/free-brands-svg-icons"
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import {FindTheObject} from './Helper'


export default function(props){
    const {CustomerTypeName,price,QLY,customertypeid,index,setobj}=props;
   

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    
    
function componentDidUpdate() {
    console.log('update')
   }
   function removefromcart(customertypeid){
  
      let filteredArray = props.Totalcart.filter(value => value.customertypeid != customertypeid);
      console.log(filteredArray)
      
       setobj(filteredArray)
       forceUpdate()
       props.forceupdate()
    
       console.log("now cart=")
       console.log(props.Totalcart)
      
       // console.log(filteredArray)

     
     
   }


    return(
        <>
        <Row>
            <Col md={3}><span>{CustomerTypeName}</span></Col>
            <Col md={3}><span>{price}₪</span></Col>
            <Col md={3}><span>{QLY}</span></Col>
            <Col md={3}><a value={customertypeid} onClick={()=>{ props.remove(customertypeid); props.setBuilding(props.building+1)}} ><img src={process.env.PUBLIC_URL + '/x.png'}/></a></Col>


        </Row>
        </>
 

    );



}
