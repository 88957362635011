 
 export function jsonobj() {



  const customers = [
    {
      id: 1,
      price: 5,
      CustomerTypeName: "מבוגר",
      
     
    },
    {
      id: 2,
      price: 30,
      CustomerTypeName: "צעיר",
    },
    {
      id: 3,
      price: 15,
      CustomerTypeName: "חייל",
    },
    {
      id: 4,
      price: 10,
      CustomerTypeName: "מורה דרך",
    },
    
  ];


  return customers


 }

 export function MainAtraction(){

  const maineventobject =[{

    id:10,
    
    "customerstype":[
       {
        
          id: 1,
          price: 5,
          CustomerTypeName: "מבוגר",
          
         
        },
        {
          id: 2,
          price: 30,
          CustomerTypeName: "צעיר",
        },
        {
          id: 3,
          price: 15,
          CustomerTypeName: "חייל",
        },
        {
          id: 4,
          price: 10,
          CustomerTypeName: "מורה דרך",
        },
       
    ],
    "AttractionData":[{
      "title":"צריף בן גוריון בנגב",
      "description":"לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף נולום ארווס סאפיאן - פוסיליס קוויס",
      
    }]



 }




]
 return maineventobject;

 }






 export function AddisionalAtract() {

  const data = [
    {
      id: 1,
      price: 20,
      "AttractionData":[{
        "title":"חוברת הדרכה לילדתים ",
        "description":"לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף נולום ארווס סאפיאן - פוסיליס קוויס",
        
      }],
      
     
    },
    {
      id: 2,
      price: 30,
      "AttractionData":[{
        "title":"צריף בן גוריון בנגב",
        "description":"לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף נולום ארווס סאפיאן - פוסיליס קוויס",
        
      }],

   
    },
    {
      id: 3,
      price: 15,
      "AttractionData":[{
        "title":"נסיעה ברכב",
        "description":"לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף נולום ארווס סאפיאן - פוסיליס קוויס",
        
      }],

      
    },
    {
      id: 4,
      price: 10,
      "AttractionData":[{
        "title":"צריף בן גוריון בנגב",
        "description":"לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף נולום ארווס סאפיאן - פוסיליס קוויס",
        
      }],

     
    },
    
  ];


  return data






 }
 
 
