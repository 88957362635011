
import React, { useEffect, useState } from "react"
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'



export default function(props){
    const [price,setprice]=useState(0);
    const [name,setname]=useState(0);
    const {MainAtractCart} = props;

    const {type}=props;

    useEffect(()=>{
        var tempprice=0;
        for(var i=0;i<MainAtractCart.length;i++){
            setname(MainAtractCart[i]['title']);
            tempprice+=MainAtractCart[i].price*MainAtractCart[i].qly
            
           
        }
        setprice(tempprice);


    },[MainAtractCart])
    
    
   

    if(price===0)
    {
        return(
            <li></li>
    
    
        );
    }
    else{
        if(type==='price'){
            return(
                <li>{'₪'+price }</li>
        
        
            );
        }
        if(type==='atractname'){
            return(
                <li>{name}</li>
        
        
            );

        }
    }
    
    

  





}
