import logo from './logo.svg';
import {React ,useReducer, useEffect, useState} from "react";
import './App.css';
import {jsonobj,AddisionalAtract,MainAtraction} from './data';
import Maincartlist from './components/ComponenetHelper/Maincartlist';
import Mainevent from './components/mainevent'
import Orderdetails from './components/orderdetails';
import Additionalatt from './components/additionalattraction'
import Header from './components/header';
import { Container, ThemeProvider ,Button,Col,Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Welcome from './components/Welcome';
import Adisionalcartlist from './components/ComponenetHelper/Adisionalcartlist';
import Payform from './components/Payform'
import Thanks from './components/thanks';
import {Sendreq} from './req';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


function App() {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [exampledata,setexampledata]=useState(jsonobj);
  const [Addtobaket,SetAddtobaket]=useState(false);
  const [MainTotalCart,SetMainTotalCart]=useState([]);
  const [AdditionalAttraction,SetAdditionalAttraction]=useState([])
  const [restartQLY,SetrestartQLY]=useState(false);
  const [TotalCart,SetTotalCard]=useState({MainAtract:[],Addisionalatract:[]})
  const [TotalPrices,SetTotalPrices]=useState(0);
  const [MainAtractCart,SetMainAtractCart]=useState([]);
  const [AddisionalAtractCart,SetAddisionalAtractCart]=useState([]);

  const [SubAtracts,SetSubAtracts]=useState([]);
  const [AtractType,SetAtractType]=useState(1);

  const [DataObject,SetDataObject]=useState([]);

  const [Loading,SetLoading]=useState(true);

  const [StartDates,SetStartDates]=useState([]);
  const [DatePick,SetDatePick]=useState([]);
  const [LockMainCard,SetLockMainCard]=useState(true);


  const [MainAtractID,SetMainAtractID]=useState(0);

  const [OpenHours,SetOpenHours]=useState([])

  const [AllowBuy,SetAllowBuy]=useState(false);

  const [SelectedDate,SetSelectedDate]=useState("בחירת תאריך");
  const [SelectedHour,SetSelectedHour]=useState("בחירת שעה");


useEffect(async ()=>{
  if(SelectedHour ==='בחירת שעה'){

  SetAllowBuy(false)
  }

  if(SelectedDate !=="בחירת תאריך"){
    const OpenHoursObj=await Sendreq("https://bengurion.wdev.co.il/api/client/ClientProcesses/ReturnOpenHours",{"date":SelectedDate,"atractid":DataObject['MainData'][0]['id']});
    SetOpenHours(OpenHoursObj);
  }
    if(SelectedDate !=="בחירת תאריך" && SelectedHour !=='בחירת שעה'){
    ////  alert("קריאה ל API "+SelectedDate+" Hour : "+SelectedHour );
     const Subatract=await Sendreq("https://bengurion.wdev.co.il/api/client/ClientProcesses/GetSubAtractByDateTime",{"time":SelectedHour,"date":SelectedDate,"mainatract":DataObject['MainData'][0]['id']});
     
     SetAllowBuy(true);
     console.log(Subatract);
     SetSubAtracts(Subatract);

     //console.log(SubAtracts);
    }
    else{
      SetAllowBuy(false)
    }
    


 

  //alert()


},[SelectedDate,SelectedHour])



function SetHourSelected(Hour){

  SetSelectedHour(Hour);


}

///function SetDate()


  const updateAddisionalAtractCart=(object,action)=>{
    const exist=AddisionalAtractCart.find((x)=>x.id===object.id);

    if(exist){
      //console.log("קיים");
      if(action=='add'){
        SetAddisionalAtractCart(
          AddisionalAtractCart.map((x) =>
          x.id === object.id ? { ...exist, qly: exist.qly + 1 } : x
        )


        );

      }
      else{
        SetAddisionalAtractCart(
          AddisionalAtractCart.map((x) =>
          x.id === object.id ? { ...exist, qly: exist.qly - 1 } : x
        )


        );
      }

    }

    else{
      SetAddisionalAtractCart([...AddisionalAtractCart,{...object,qly:1}]);
    }
  }

  const updateMainAtractCart=(object,action)=>{
    const exist=MainAtractCart.find((x)=>x.id===object.id);
    if(exist){

      if(action==='add'){
        SetMainAtractCart(
          MainAtractCart.map((x) =>
            x.id === object.id ? { ...exist, qly: exist.qly + 1 } : x
          )
        );
  


      }
      else{
        SetMainAtractCart(
          MainAtractCart.map((x) =>
            x.id === object.id ? { ...exist, qly: exist.qly - 1 } : x
          )
        );
      }
   
    
     /// console.log(exist);
    }
    else{
      SetMainAtractCart([...MainAtractCart,{...object,qly:1}]);

    }



  }
  const UpdateTotalPrice=(action,price)=>{
    ///console.log(action + '     -    ' + price)
    action == 'add' ? SetTotalPrices(price+TotalPrices) : SetTotalPrices(TotalPrices-price)

  }

  async function SetDate(Date){
    ///SetDatePick(Date);
    var selected = document.getElementById("HourSelect");
    selected.selectedIndex = 0;
    SetSelectedHour("בחירת שעה")
    const dates=await Sendreq("https://bengurion.wdev.co.il/api/client/ClientProcesses/PickedDate",{"Date":Date});
    console.log(dates);
    SetDatePick(dates)
    SetSelectedDate(Date)
    

    ///alert(Date);

  }





  useEffect(()=>{

    if(TotalPrices===0)
    {
      SetAddtobaket(false);
    }
    else{
      SetAddtobaket(true);
    }
  



  },[TotalPrices])

  useEffect(async ()=>{
    ///console.log(MainAtraction())
    SetLoading(true);

    const dates=await Sendreq("https://bengurion.wdev.co.il/api/client/ClientProcesses/Getdates",{});
    //console.log(dates);
    SetStartDates(dates);

    //default Data By today date

    let yourDate = new Date().toISOString().split('T')[0];
    console.log(yourDate)
    const Data=await Sendreq("https://bengurion.wdev.co.il/api/client/ClientProcesses/GetData",{Type:AtractType,Date:yourDate});



    SetDataObject(Data);

    let SubAtractions=await Sendreq("https://bengurion.wdev.co.il/api/client/ClientProcesses/GetDataSubAtractionsdefault",{BelongTo:Data['MainData'][0]['id']});

    SetOpenHours(Data['OpenHours']);

    console.log(Data);
    SetMainAtractID(Data['MainData'][0]['id']);

    console.log(SubAtractions);
    SetSubAtracts(SubAtractions)
    SetLoading(false);







  },[AtractType])




  useEffect(()=>{
    var temp=TotalCart;
    temp['MainAtract']=MainTotalCart;
    temp['Addisionalatract']=AdditionalAttraction;

    SetTotalCard(temp);
    forceUpdate()

  },[AdditionalAttraction,MainTotalCart])


  useEffect(()=>{

    if(MainTotalCart.length===0){
      SetrestartQLY(true)
    }
    else{
      SetrestartQLY(false)
    }



    ///console.log(MainTotalCart)
  },[MainTotalCart])
 

if(Loading){
  return (
    <>
    <h1>טוען ...</h1>
    
    </>
    );
   
}

else{


  return (
    <>
       <Router>
       <div className="App">

       <header className="App-header">
         <Header />
      </header>
      <ThemeProvider dir="rtl">
           <Switch>
        <Route exact path="/">
          <Welcome AtractType={AtractType} SetAtractType={SetAtractType} />
                    <Mainevent AllowBuy={AllowBuy} SelectedHour={SelectedHour}  SetHourSelected={SetHourSelected} LockMainCard={LockMainCard} key={6554} DatePick={DatePick} SetDate={SetDate} UpdateTotalPrice={UpdateTotalPrice} StartDates={StartDates} objectdata={MainAtraction()[0]} ticketdata={jsonobj()} OpenHours={OpenHours} DataObjectBASIC={DataObject['MainData'][0]} DataObjectCustomersType={DataObject['CustomerType']} updateMainAtractCart={updateMainAtractCart}  />

                <Container>

                <div className="RestAttractions">
                  {AtractType===1 && <h1>העשירו את הביקור בצריף עם אטרקציות נוספות</h1>}

              </div>
              </Container>



              {SubAtracts && SubAtracts.map((item,index)=>
              
              <Additionalatt  key={item.id*10} {...item} UpdateTotalPrice={UpdateTotalPrice} updateAddisionalAtractCart={updateAddisionalAtractCart} AdditionalAttraction={AdditionalAttraction} SetAdditionalAttraction={SetAdditionalAttraction} index={index} MainTotalCart={MainTotalCart} restartQLY={restartQLY} data={AddisionalAtract()[0]} type={1} basket={Addtobaket} />

              
              
              )}


    {/*           <Additionalatt UpdateTotalPrice={UpdateTotalPrice} updateAddisionalAtractCart={updateAddisionalAtractCart} AdditionalAttraction={AdditionalAttraction} SetAdditionalAttraction={SetAdditionalAttraction} index={2} MainTotalCart={MainTotalCart} restartQLY={restartQLY}  data={AddisionalAtract()[1]} type={1} basket={Addtobaket}/>
              <Additionalatt UpdateTotalPrice={UpdateTotalPrice} updateAddisionalAtractCart={updateAddisionalAtractCart} AdditionalAttraction={AdditionalAttraction} SetAdditionalAttraction={SetAdditionalAttraction} index={3} MainTotalCart={MainTotalCart} restartQLY={restartQLY}  data={AddisionalAtract()[2]} type={1} basket={Addtobaket}/> */}

              <Container>
                <div className="TotalCarT">
                  <Row>
                    <Col className="SepLine" md={6}>
                        <Row>
                            <Col md={6}>
                              <div className="attractionType">
                                <span className="MainCheckOutTitle">סוגי פעילות</span>
                                <ul>

                                           <Maincartlist type={"atractname"} MainAtractCart={MainAtractCart} />

                                           {AddisionalAtractCart.map((item,index)=>
                                           <Adisionalcartlist key={index} type={"atractname"} fullobject={AddisionalAtractCart} cart={item} />
                                           
                                           )}

                                           

                                </ul>

                              </div>
                            </Col>
                            <Col md={6}>
                                  <div className="TotaAttractPrices">
                                    <span className="MainCheckOutTitle">מחיר</span>
                                    <ul className="MainCheckoutPricesList">
                                    <Maincartlist type={"price"} MainAtractCart={MainAtractCart} />

                                    {AddisionalAtractCart.map((item,index)=>
                                           <Adisionalcartlist key={index} type={"price"} fullobject={AddisionalAtractCart} cart={item} />
                                           
                                           )}

                               {/*      {TotalCart["MainAtract"].map((item,index)=>
                                                <li>{item.price}</li>


                                          )}


                                    {TotalCart["Addisionalatract"].map((item,index)=>
                                                <li>{item.price}</li>


                                          )} */}

                                </ul>

                                  </div>

                            </Col>

                        </Row>
                    </Col>
                    <Col  md={6}>
                      <div className="TotalPriceCheckoutSession ">

                        <span>סה"כ לתשלום : ₪{TotalPrices}</span>
                        <div className="d-flex justify-content-center">
                        <Link to={Addtobaket ? "/orderdetails" : "#"} >
                        <Button disabled={!Addtobaket} onClick={()=>{sessionStorage.setItem("order",JSON.stringify({mainatractid:MainAtractID,mainatract:MainAtractCart,adisional:AddisionalAtractCart,useCoupon:false,totalprices:TotalPrices,SelectedDate:SelectedDate,SelectedHour:SelectedHour}) ) }} className="nextbtn" variant="primary"></Button>{' '}
                        </Link>
                       

                        </div>

                      </div>

                    </Col>

                  </Row>


                </div>






          {/*     <div className="d-flex justify-content-center">
              <Button className="nextbtn" variant="primary"><FontAwesomeIcon  icon={faChevronLeft}></FontAwesomeIcon> המשך</Button>{' '}
              </div> */}
              </Container>
          </Route>
          <Route path="/orderform">
            <Payform />
          </Route>
          </Switch>

          <Route path="/orderdetails">
            <Orderdetails />
          </Route>
          <Route path="/thanks">
            <Thanks />
          </Route>
          
       


        </ThemeProvider>
      </div>

    </Router>









   </>
  );
}

}

export default App;
