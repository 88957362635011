
import {React,useState} from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';

import Payform from './Payform'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
  } from "react-router-dom";
  
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
const axios = require('axios');
export default function(){
    const history = useHistory();
    const [FirstName,SetFirstName]=useState('');
    const [LastName,SetLastName]=useState('');
    const [Email,SetEmail]=useState('');
    const [Phone,SetPhone]=useState('');
    const [cupon,Setcupon]=useState('');
    const [OrderCart,SetOrderCart]=useState(JSON.parse(sessionStorage.getItem("order")));
    console.log(OrderCart)

    function SendPost(){
    var bodyFormData = new FormData()
    bodyFormData.append("data", JSON.stringify({amit:"try"}))
    axios({
        method: 'post',
        url: "https://bengurion.wdev.co.il/api/client/orderdata",
        data: bodyFormData,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then(function (response) {
      
        history.push('/orderform');
        console.log(response.data);
        console.log(response.status);
    
    }).catch((error) => {
        alert(error);
})

    }

    return(
            
       
        <Container className="p-4">
             <div className="orderDetailspage d-flex justify-content-center"> 
          
            <div >
          
            <h1 style={{textAlign:"center"}}><span style={{color:"#40AE49"}}>מספר הזמנה : </span> {OrderCart.orderid}</h1>
            <h1 style={{textAlign:"center",marginTop:"35px"}}>פרטי מזמין</h1>
            <div className="d-flex justify-content-center"> 
               <Form >
           
                    <Row className="orderData" >
                    
                         <Col  md={6}>
                         <div className="inputblock">
                            <label  className="inputlabel" >שם פרטי</label>
                             <Form.Control value={OrderCart.orderpersondetails.FirstName}  />
                             </div>
                        </Col>

                        <Col md={6}>
                        <div className="inputblock">
                        <label className="inputlabel" >שם משפחה</label>
                        </div>
                        < Form.Control value={OrderCart.orderpersondetails.LastName}  />
                         </Col>

                         
                 
                         <Col  md={6}>
                         <div className="inputblock">
                            <label className="inputlabel" >מייל</label>
                             <Form.Control value={OrderCart.orderpersondetails.Email}   />
                             </div>
                        </Col>
                        <Col   md={6}>
                        <div className="inputblock">
                        <label className="inputlabel" >טלפון</label>
                        </div>
                        < Form.Control value={OrderCart.orderpersondetails.Phone}  />
                         </Col>   
                         <div className="space"></div>

                         
                   
                        <div className="space"></div>
                        
                

                     </Row>


                
                
             

                </Form>

          
                </div>

                <Row className="Reception">
                    <Col >
                    <span>שם אטרקציה</span>
                    </Col>
                    <Col >
                    <span>כמות</span>
                    </Col>
                    <Col >
                    <span>מחיר</span>
                    </Col>

                </Row>
                
                    {OrderCart.mainatract.map((item,index)=>
                    <>
                   <Row className="ReceptionOrder">
                     <Col ><img className="ticketicone" src='ticketicon.png' /><span>{item.title +' - '+ item.CustomerTypeName}</span></Col>

                     <Col><span>{item.qly}</span></Col>

                     <Col><span> {Number(item.price) * Number(item.qly) } ₪</span></Col>
                     </Row>
                     </>
                  


                    )}




{OrderCart.adisional.map((item,index)=>
                    <>
                    <Row className="ReceptionOrder">
                    <Col><img className="ticketicone" src='ticketicon.png' /><span>{item.title}</span></Col>

                     <Col><span>{item.qly}</span></Col>

                     <Col><span> {Number(item.price) * Number(item.qly) } ₪</span></Col>
                     </Row>
                     </>
                    
                    )}
                    
                
                  

               


          </div>
          </div>


          <div className="TotalPriceOrder">
          <h1>סה"כ שולם : <span style={{color:"#40AE49"}}>{OrderCart.totalprices} ₪</span></h1>
          </div>
        </Container>
        

    );



}
