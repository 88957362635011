
import React, { useEffect, useState } from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import OnlyAmount from "./OnlyAmount";
import WithClientType from "./WithClientType"
import Numericadditionalatrakts from "./Numericadditionalatrakts";
import NumericRow from './numericnumbers';
import SubAtractnumericnumbers from './Numericadditionalatrakts'


export default function(props){
    
 const {updateAddisionalAtractCart,AtractID,Price,AtractTitle,id,OutOfStock}=props;

 ///AtractTitle={AtractTitle} id={id}
    
 ////<NumericRow key={item.id} AllowBuy={AllowBuy} LockMainCard={LockMainCard} title={DataObjectBASIC.AtractTitle} DataObjectCustomersType={DataObjectCustomersType} key={item.id} {...item} restdata={objectdata.AttractionData[0]} qly={qly} UpdatePrice={setTotalAtractPrice} updateMainAtractCart={updateMainAtractCart}/>

 if(OutOfStock){
     return(
         <h1>אזל המלאי!</h1>
     )
 }

    if(props.type===1){

    return(
        <>
        <Numericadditionalatrakts Price={Price} AtractTitle={AtractTitle} id={id} updateAddisionalAtractCart={updateAddisionalAtractCart} dis={props.dis} UpdateTotalPrice={props.UpdateTotalPrice} updateAddisionalAtractCart={props.updateAddisionalAtractCart} MainTotalCart={props.MainTotalCart} restartQLY={props.restartQLY}  qly={props.qly} setqly={props.setqly} data={props.data} basket={props.basket} />

        </>

    );

    }

    if(props.type===2){

        return(
            <>
          {/*  <SubAtractnumericnumbers key={Math.random()} AllowBuy={props.dis} title={AtractTitle}    qly={props.qly} UpdatePrice={null} updateMainAtractCart={updateAddisionalAtractCart}/> */}
    
            </>
    
        );
    
        }



}
