
import React, { useEffect, useState } from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'



export default function(props){
    const {qly}=props;
    const {setqly}=props;
    const {dis,Price,id,AtractTitle,updateAddisionalAtractCart}=props;


    const work=(action)=>{
       if(dis){
         setqly(action)
         var object={};
         object['title']=AtractTitle;
         object['id']=id;
         object['price']=Price;
         updateAddisionalAtractCart(object,action);
       }

    }

 /*     const UpdateQly=(action)=>{
  
         if(action == 'add'){
            setqly(qly+1);
            UpdatePrice('add',Number(price));
            ///console.log(props.data)
            var temp=props.data;
            temp['title']=restdata.title;
            updateMainAtractCart(temp,'add');

         }
         
         else{
             if(Number(qly)-1<0)
             {
                setqly(0);
             }
             else{

                setqly(qly-1);
                UpdatePrice('minus',Number(price));
               var  temp=props.data;
                temp['title']=restdata.title;
                updateMainAtractCart(temp,'minus');
             }
          
         }
        
     
    } */
  ///const [qly,setqly]=useState(0);
    //const {CustomerTypeName,price,id}=props.data;
   // const {qly}=props;
    

    
    

    return(
        <div style={{marginTop:"12px"}}>
           <Row className="midRow" >
         <Col xs={6} md={6}><img className="CrowdIcone" src={process.env.PUBLIC_URL + '/crowd.png'} /><span  className="numofcardssapn">בחירת כמות</span></Col>
            
            <Col xs={6} md={6}>

            <div class="number">
	            <span onClick={()=>{work('minus')}} className="minus">-</span>
                <Form.Control type="text" value={qly}  className />

	            <span disabled={props.dis ? false : true} onClick={()=>{ work('add')}} className="plus">+</span>
            </div>
            </Col>
            </Row>

        </div>
 

    );



}
