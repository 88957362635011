
import React, { useEffect, useState } from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'



export default function(props){
    const {UpdatePrice}=props;
    const {updateMainAtractCart,title}=props;
  
    const {Price,AtractID,Type_Name,id,AllowBuy}=props; 

    
     const UpdateQly=(action)=>{
        /// if(LockMainCard===false){

         if(AllowBuy)
         {


     
       
  
         if(action == 'add'){
            setqly(qly+1);
            if(UpdatePrice !=null)
                UpdatePrice('add',Number(Price));
            ///console.log(props.data)
            var temp={"CustomerTypeName":Type_Name,"id":id,"price":Price,"title":title};
           /// temp['title']=restdata.title;
            console.log(temp);
            updateMainAtractCart(temp,'add');

         }
         
         else{
             if(Number(qly)-1<0)
             {
                setqly(0);
             }
             else{

                setqly(qly-1);
                if(UpdatePrice !=null)
                   UpdatePrice('minus',Number(Price));
              /// var  temp=props.data;
               /// temp['title']=restdata.title;
               var temp={"CustomerTypeName":Type_Name,"id":id,"price":Price,"title":title};
                console.log(temp);
                updateMainAtractCart(temp,'minus');
             }
          
         }
      }
      //  }
       // else{
          //  alert("יש לבחור תאריך ושעת הגעה")
      //  }
        
     
    }
  const [qly,setqly]=useState(0);

  
    ///const {CustomerTypeName,price,id}=props.data;
   // const {CustomerTypeName,price,id}=props;
 //const {qly}=props;


    
    

    return(
        <div style={{marginTop:"12px"}}>
           <Container>
           <Row >
         <Col xs={4} md={4}><img class="CrowdIcone" src="/crowd.png"/><span>{Type_Name}</span></Col>
            <Col xs={2} md={2}>{Price}₪</Col>
            <Col xs={6} md={6}>

            <div className="number">
	            <span  onClick={()=>{UpdateQly('minus')}} className="minus">-</span>
                <Form.Control type="text" value={qly} className />

	            <span onClick={()=>{UpdateQly('add')}} className="plus">+</span>
            </div>
            </Col>
            </Row>
            </Container>

        </div>
 

    );



}
