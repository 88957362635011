
import {React,useEffect,useState} from "react" 
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from './Loading'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.rtl.css';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
  } from "react-router-dom";


const axios = require('axios');
export default function(){
    const history = useHistory();
    const [CreditCard,SetCreditCard]=useState('');
    const [id,Setid]=useState('');
    const [vaild,SetVaild]=useState('');
    const [Digits,SetDigits]=useState('');
    const [OrderCart,SetOrderCart]=useState(JSON.parse(sessionStorage.getItem("order")));
    const [Loding,SetLoding]=useState(false);
    const [Years,SetYears]=useState([]);
    const [DigitMonth,SetDigitMonth]=useState('');
    const [DigitYear,SetDigitYear]=useState('');
    
    console.log(sessionStorage.getItem("order"));
    

    const optionMolabel=['01','02','03','04','05','06','07','08','09','10','11','12'];


    useEffect(()=>{
        const yearsarray=[];

        for(var i=0;i<8;i++){
           var getyar= new Date().getFullYear()+i;
           yearsarray.push(getyar.toString().substr(-2));
           


        }
        SetYears(yearsarray)


    },[])





    function SendPost(){
    var bodyFormData = new FormData()
    SetLoding(true);
    //alert(DigitMonth+DigitYear)
    
    bodyFormData.append("data", JSON.stringify({CreditCard:CreditCard,id:id,vaild:DigitMonth+DigitYear,Digits:Digits,total:OrderCart.totalprices,orderid:OrderCart['orderid'],email:OrderCart['orderpersondetails']['Email'],name:OrderCart['orderpersondetails']['FirstName']+' '+OrderCart['orderpersondetails']['LastName'],phone:OrderCart['orderpersondetails']['Phone']}))
    axios({
        method: 'post',
        url: "https://bengurion.wdev.co.il/api/client/orderdata",
        data: bodyFormData,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then(function (response) {
       //alert(response.data.status);
       if(response.data.status==='002')
       {
        alertify.alert().setHeader('<h3 class="errormessagealert">שגיאה!</h3>').setting({'label':'המשך'}).setContent("הסליקה נכשלה . אנא בדוק את פרטי האשראי ונסה שנית").show();
       }
       else{
        history.push('/thanks');
       }
      
      
        console.log(response.status);
        SetLoding(false);
    
    }).catch((error) => {
        alert(error);
})

    }

    console.log(OrderCart)
   // const 
    //position:relative
    return(
<>
      
        <Container className="p-4">
             <div className="orderDetailspage d-flex justify-content-center"> 
            <div >
           
            <h1 style={{textAlign:"center"}}>פרטי תשלום</h1>
             

               <Form style={{marginTop:"30px"}}>
           
                    <Row className="PaymentRow">
                    
                         <Col  md={12}>
                         <div className="inputblock">
                      
                             <Form.Control placeholder="מספר כרטיס אשראי" className="PaymentInput" value={CreditCard} onChange={(e)=>{SetCreditCard(e.target.value)}}  />
                             </div>
                        </Col>

                        <Col md={6} >
                        <div className="inputblock">
                        <Form.Select onChange={(e)=>{SetDigitMonth(e.target.value)}} className="PaymentInput" size="sm">
                            <option>תוקף חודש</option>
                            {optionMolabel.map((item,index)=>
                            
                            < option key={index} value={item}>{item}</option>
                            )}
                       


                        
                        
                        </Form.Select>
                        </div>
                     </Col>
                     <Col md={6} >
                        <div className="inputblock">
                        <Form.Select onChange={(e)=>{SetDigitYear(e.target.value)}} className="PaymentInput" size="sm">
                        <option>תוקף שנה</option>
                        {Years.map((item,index)=>
                         < option key={index} value={item}>{item}</option>
                        
                        )}
                        </Form.Select>
                        </div>
                     </Col>
                        <Col md={12}>
                        <div className="inputblock">
                        </div>
                        < Form.Control placeholder="מספר תעודת זהות" className="PaymentInput" value={id} onChange={(e)=>{Setid(e.target.value)}} />
                         </Col>

                         
                 
                    
                        <Col   md={12}>
                        <div className="inputblock">
                        </div>
                        < Form.Control placeholder="3 ספרות בגב הכרטיס" className="PaymentInput" value={Digits} onChange={(e)=>{SetDigits(e.target.value)}} />
                         </Col>                   
                     </Row>


               

               <div className="space"></div>
               <div className="btnblock d-flex justify-content-center">
               <Button onClick={SendPost} disabled={Loding} className="payment" variant="primary"><FontAwesomeIcon  icon={faChevronLeft}></FontAwesomeIcon> {Loding ? "טוען תשלום ...":"שלם "+OrderCart.totalprices +"₪"} </Button>{' '}
               </div>
                </Form>
               




          </div>
          </div>
        </Container>
        
</>
    );



}
