
import {React,useEffect,useState} from "react"
import { Button,Container ,Row,Card,Col,Form,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import TypeRounter from "./ComponenetHelper/TypeRounter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import TotaladdisionalPrice from "./ComponenetHelper/TotaladdisionalPrice";
import CartPriceAdd from "./ComponenetHelper/CartPriceAdd";
import OpenTimeDIV from "./ComponenetHelper/OpenTimeDIV";
import SubAtractnumericnumbers from './ComponenetHelper/SubAtractnumericnumbers'

export default function(props){
    const [qly,setqly]=useState(0);
    const [maximumCards,SetmaximumCards]=useState(0);
    const [totalpriceCard,SettotalpriceCard]=useState(0);
    const [addisionalCardOrderobj,SetaddisionalCardOrderobj]=useState([])
    const [callPrice,Setcallprice]=useState(false);
    const [TotalCurretPrice,SetTotalCurretPrice]=useState(0);
    const {data,AtractTitle,MainPhoto,Description,Price,id}=props;
    const {updateAddisionalAtractCart,}=props;
    const {UpdateTotalPrice,OpenTime,CardType,CustomerType,AvlStock}=props;
    const [addcardPower,setaddcardPower]=useState(false);
    

    const updateqly2=(action,price)=>{

        if(action==='add')
        {
           // setqly(qly+1);

           
     
            SettotalpriceCard(Number(price)+totalpriceCard);
            UpdateTotalPrice('add',Number(price))
           
        }
     
            else{
                setqly(qly-1);
               
                var temp=data;
                var object={};
                object['title']=AtractTitle;
                object['id']=id;
                object['price']=Price;
               //// updateAddisionalAtractCart(object,'minus');
                SettotalpriceCard(totalpriceCard-Number(price));
                UpdateTotalPrice('minus',Number(price));
             
            }
        }

    
 

    const updateqly=(action)=>{

        if(action==='add')
        {
            setqly(qly+1);

           
            var temp=data;
            var object={};
            object['title']=AtractTitle;
            object['id']=id;
            object['price']=Number(Price);
           /// updateAddisionalAtractCart(object,'add');
            SettotalpriceCard(Number(Price)*(qly+1));
            UpdateTotalPrice('add',Number(Price))
           
        }
        else{
            if(qly-1<0){
                setqly(0)
            }
            else{
                setqly(qly-1);
               
                var temp=data;
                var object={};
                object['title']=AtractTitle;
                object['id']=id;
                object['price']=Price;
               //// updateAddisionalAtractCart(object,'minus');
                SettotalpriceCard(Number(Price)*(qly-1));
                UpdateTotalPrice('minus',Number(Price));
             
            }
        }

    }

    function OutOfStock(){
    
            if(AvlStock <=0){
                return true;
            }
            else{
                return false;
            }

        

    }





    return(
        <Container>

            <div className={'addtionalEventCard ' + (props.basket ? "Open" : "Close")}>
            <Row>

                <Col md={3}>
                <div className="CardPhoto">
                    <img src={MainPhoto}></img>
                </div>
                </Col>

                <Col className="colafter" md={6}>
                <div className="CardTextAdt">
                        <h1> {AtractTitle} </h1>
                        <p style={{color:"#4E5861"}}>{Description}</p>
                        <OpenTimeDIV OpenHours={OpenTime} />

                        {AvlStock !=null && <span>מלאי זמין : {AvlStock}</span>}


                    </div>
                </Col>


                <Col md={3}>


                    <div className="CardPrices">
                        <div style={{marginBottom:"30px"}}>
                        {CardType==='0' && <span className="TotalCostAtr" >עלות כרטיס : <span className="pricespan" style={{fontSize:"37px",color:"#40AE49"}} > ₪{Price}</span> </span>}
                    </div>



                {CardType==='0' ? <TypeRounter OutOfStock={OutOfStock()} Price={Price} updateAddisionalAtractCart={updateAddisionalAtractCart} dis={props.basket} qly={qly} setqly={updateqly} basket={props.basket} type={props.data} AtractTitle={AtractTitle} id={id} type={props.type} /> : false }

                {CardType==='1' && CustomerType.map((item,index)=>
                <SubAtractnumericnumbers OutOfStock={OutOfStock()} updateMainAtractCart={updateAddisionalAtractCart} {...item} AllowBuy={props.basket} qly={qly} Update={updateqly2}  title={AtractTitle} AtractID={id} />
                
                
                )}
                  <div className="checkoutline"></div>


                <TotaladdisionalPrice totalprice={totalpriceCard}/>



                    <div className="d-flex justify-content-center">

{/*                     <Button disabled={props.basket ? false : true} className="btnaddtobask" onClick={()=>{setaddcardPower(true)}} style={{marginTop:"15px"}}  variant="success">הוסף לסל</Button>
 */}                    </div>

                    </div>


                </Col>

            </Row>
            </div>

        </Container>

    );



}
